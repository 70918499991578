<template>
  <div>
    <!-- 头部 -->
    <section class="section p0 panel header_video">
      <div class="col-lg-12 p0 container_max">
        <video
          autoplay
          muted
          playsInline="{true}"
          class="video_box"
          webkit-playsinline="true"
          x-webkit-airplay="true"
          x5-playsinline="true"
          x5-video-orientation="portraint"
          src="https://blackwaves-1329498277.cos.ap-shanghai.myqcloud.com/images/case/sea/Top.mp4"
        ></video>

        <h1 class="T128px_Regular colorfff header_title wow animate__fadeInUp">
          {{ $t("sanhaiJing.headerTitle") }}
        </h1>

        <div class="header_video_bottom flex-wb">
          <div class="left_box col-lg-6">
            <header class="T36px_Regular colorfff">
              <span class="jb_title">{{ $t("sanhaiJing.jb_title1") }}</span>
              <span class="xian"></span>
              <span>{{ $t("sanhaiJing.jb_title2") }}</span>
            </header>
            <div class="T18px_Regular colorfff left_box_tips">
              {{ $t("sanhaiJing.left_box_tips") }}
            </div>
          </div>
          <div
            class="right_box col-lg-6 flex-wb"
            style="align-items: flex-start"
          >
            <div
              class="col-lg-4 flex-cc"
              v-for="(item, index) in headerList"
              :key="index"
            >
              <header class="right_box_header">
                <img :src="item.url" alt="" style="width: 1.6rem" />
                <span
                  class="T24px_Regular colorfff max_title"
                  :style="
                    index == 2
                      ? 'border-right: .0313rem solid transparent;'
                      : ''
                  "
                  >{{ item.title }}</span
                >
              </header>
              <div class="T18px_Regular colorfff flex-c chlie_box">
                <span v-for="(items, ins) in item.chlie" :key="ins">{{
                  items
                }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- 项目背景 -->
    <section class="section p0 project_bj_box">
      <div class="container">
        <header class="project_bj_header T36px_Regular wow animate__fadeInUp">
          <span class="color1D73FF">
            {{ $t("sanhaiJing.project_bj_title1") }}</span
          >
          <span class="colorfff">
            {{ $t("sanhaiJing.project_bj_title2") }}</span
          >
        </header>
        <div
          class="proj_bj_text proj_bj_p1 T16px_Regular colorfff wow animate__fadeInUp"
          data-wow-delay=".2s"
        >
          {{ $t("sanhaiJing.project_bj_p1") }}
        </div>
        <div
          class="proj_bj_text T16px_Regular colorfff wow animate__fadeInUp"
          data-wow-delay=".3s"
        >
          {{ $t("sanhaiJing.project_bj_p2") }}
        </div>
      </div>

      <div class="project_bj_image_g_heing">
        <div class="project_bj_image">
          <transition
            appear
            name="animate__animated animate__bounce"
            enter-active-class="animate__fadeInTopRight"
            leave-active-class="animate__fadeOutTopRight"
          >
            <img
              v-show="showElement"
              class="project_image_1"
              src="@/assets/images/hire/project_bj_1.png"
              alt=""
            />
          </transition>

          <transition
            appear
            name="animate__animated animate__bounce"
            enter-active-class="animate__fadeInTopLeft"
            leave-active-class="animate__fadeOutTopLeft"
          >
            <img
              v-show="showElement"
              class="project_image_2"
              src="@/assets/images/hire/project_bj_2.png"
              alt=""
            />
          </transition>
        </div>
      </div>
    </section>

    <!-- 项目挑战 -->
    <section class="section project_challenge_box">
      <div class="container">
        <header class="project_bj_header T36px_Regular wow animate__fadeInUp">
          <span class="color1D73FF">
            {{ $t("sanhaiJing.challenge_title1") }}
          </span>
          <span class="colorfff">{{ $t("sanhaiJing.challenge_title2") }}</span>
        </header>
        <div
          class="proj_bj_text proj_bj_p1 T16px_Regular colorfff wow animate__fadeInUp"
          data-wow-delay=".2s"
        >
          {{ $t("sanhaiJing.challenge_p1") }}
        </div>
        <div
          class="proj_bj_text T16px_Regular proj_bj_p2 colorfff flex-c wow animate__fadeInUp"
          data-wow-delay=".25s"
        >
          <span class="T24px_Regular">
            {{ $t("sanhaiJing.challenge_p2_title") }}
          </span>
          {{ $t("sanhaiJing.challenge_p2") }}
        </div>
        <div
          class="proj_bj_text T16px_Regular proj_bj_p3 colorfff flex-c wow animate__fadeInUp"
          data-wow-delay=".3s"
        >
          <span class="T24px_Regular">
            {{ $t("sanhaiJing.challenge_p3_title") }}
          </span>
          {{ $t("sanhaiJing.challenge_p3") }}
        </div>
        <div
          class="proj_bj_text T16px_Regular proj_bj_p4 colorfff flex-c wow animate__fadeInUp"
          data-wow-delay=".35s"
        >
          <span class="T24px_Regular">
            {{ $t("sanhaiJing.challenge_p4_title") }}
          </span>
          {{ $t("sanhaiJing.challenge_p4") }}
        </div>
      </div>

      <div class="carousel_box">
        <el-carousel indicator-position="outside" type="card" :autoplay="false">
          <el-carousel-item v-for="(item, index) in carouselList" :key="index">
            <div class="flex-cc carousel_item">
              <!-- <img :src="item.url" class="carousel_img" alt="" /> -->
              <video
                autoplay
                muted
                playsInline="{true}"
                class="carousel_img"
                webkit-playsinline="true"
                x-webkit-airplay="true"
                x5-playsinline="true"
                x5-video-orientation="portraint"
                :src="item.url"
              ></video>
              <span class="T12px_Regular colorfff">{{ item.title }}</span>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </section>
    <!-- 项目总结 -->
    <section class="section project_summarize_box">
      <div class="container">
        <header class="project_bj_header T36px_Regular wow animate__fadeInUp">
          <span class="color1D73FF">
            {{ $t("sanhaiJing.project_summarize1") }}
          </span>
          <span class="colorfff">
            {{ $t("sanhaiJing.project_summarize2") }}
          </span>
        </header>
        <div
          class="proj_bj_text proj_bj_p1 T16px_Regular colorfff wow animate__fadeInUp"
          data-wow-delay=".2s"
        >
          {{ $t("sanhaiJing.project_summarize_proj_bj_text") }}
        </div>
        <video
          autoplay
          muted
          playsInline="{true}"
          class="summarize_info"
          webkit-playsinline="true"
          x-webkit-airplay="true"
          x5-playsinline="true"
          x5-video-orientation="portraint"
          src="https://blackwaves-1329498277.cos.ap-shanghai.myqcloud.com/images/case/sea/Bottom.mp4"
        ></video>
      </div>

      <div class="customized_box flex-wb">
        <div class="flex-c">
          <span class="T36px_Regular colorfff">
            {{ $t("customized.title") }}
            <span class="color1D73FF">{{ $t("customized.title1") }}</span>
          </span>
          <span class="T16px_Regular color818181">
            {{ $t("customized.tipe") }}
          </span>
        </div>
        <div class="flex-c">
          <span class="T14px_Regular colorfff tips_net">
            {{ $t("customized.tipe_ri") }}
          </span>
          <span
            @click="routerBtn('/contact')"
            class="T20px_Regular colorfff my_btn animation_right"
          >
            {{ $t("customized.btn") }}
          </span>
        </div>
      </div>

      <div class="case_box">
        <header class="case_header T36px_Regular colorfff">
          {{ $t("moXun.genduo") }}
        </header>
        <div class="case_list flex-w col-lg-12">
          <div
            class="case_item flex-c col-lg-4"
            @click="routerBtn(item.viewUrl)"
            v-for="(item, index) in caseList"
            :key="index"
          >
            <div class="case_image_box">
              <img :src="item.url" class="case_image" alt="" />
            </div>
            <span class="T24px_Regular colorfff case_title">{{
              item.title
            }}</span>
            <span class="T14px_Regular colorfff case_tips">{{
              item.tips
            }}</span>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { gsap } from "gsap";
import ScrollMagic from "scrollmagic";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";

ScrollMagicPluginGsap(ScrollMagic, gsap);
gsap.registerPlugin(ScrollTrigger);

import { WOW } from "wowjs";
export default {
  data() {
    return {
      showElement: false,
      headerList: [
        {
          title: this.$t("sanhaiJing.headerList1_title"),
          url: "https://blackwaves-1329498277.cos.ap-shanghai.myqcloud.com/images/hire/s1.png",
          chlie: [
            this.$t("sanhaiJing.headerList1_chlie1"),
            this.$t("sanhaiJing.headerList1_chlie2"),
            this.$t("sanhaiJing.headerList1_chlie3"),
            this.$t("sanhaiJing.headerList1_chlie4"),
          ],
        },
        {
          title: this.$t("sanhaiJing.headerList2_title"),
          url: "https://blackwaves-1329498277.cos.ap-shanghai.myqcloud.com/images/hire/s2.png",
          chlie: [
            this.$t("sanhaiJing.headerList2_chlie1"),
            this.$t("sanhaiJing.headerList2_chlie2"),
          ],
        },
        {
          title: this.$t("sanhaiJing.headerList3_title"),
          url: "https://blackwaves-1329498277.cos.ap-shanghai.myqcloud.com/images/hire/s3.png",
          chlie: [
            this.$t("sanhaiJing.headerList3_chlie1"),
            this.$t("sanhaiJing.headerList3_chlie2"),
            this.$t("sanhaiJing.headerList3_chlie3"),
          ],
        },
      ],

      carouselList: [
        {
          url: "https://blackwaves-1329498277.cos.ap-shanghai.myqcloud.com/images/case/sea/Middle.mp4",
          title: this.$t("sanhaiJing.carouselList1_title"),
        },
      ],

      caseList: [
        {
          url: "https://blackwaves-1329498277.cos.ap-shanghai.myqcloud.com/images/hire/case1.png",
          title: this.$t("case.projecList4_name"),
          tips: this.$t("case.projecList4_tips"),
          viewUrl: "/ninja",
        },
        {
          url: "https://blackwaves-1329498277.cos.ap-shanghai.myqcloud.com/images/hire/moxun.png",
          title: this.$t("case.projecList1_name"),
          tips: this.$t("case.projecList1_tips"),
          viewUrl: "/moXun",
        },
        {
          url: "https://blackwaves-1329498277.cos.ap-shanghai.myqcloud.com/images/hire/xueyuan.png",
          title: this.$t("case.projecList3_name"),
          tips: this.$t("case.projecList3_tips"),
          viewUrl: "/college",
        },
        // {
        //   url: "https://blackwaves-1329498277.cos.ap-shanghai.myqcloud.com/images/hire/sanhj.png",
        //   title: "山海传奇",
        //   tips: "探索自然力量，激发运动激情",
        //   viewUrl: "/sanhaiJing",
        // },
      ],
    };
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  mounted() {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
    this.slideInit();
    const wow = new WOW();
    wow.init();
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      const target = this.$el.querySelector(".project_bj_image");
      const rect = target.getBoundingClientRect();
      const windowHeight =
        window.innerHeight || document.documentElement.clientHeight;

      // 检查元素是否进入视口
      if (rect.top <= windowHeight && rect.bottom >= 0) {
        setTimeout(() => {
          if (!this.showElement) {
            this.showElement = true;
          }
        }, 200);
      } else {
        setTimeout(() => {
          if (this.showElement) {
            this.showElement = false;
          }
        }, 200);
      }
    },
    /**
     * 滑动页面效果初始化
     */
    slideInit() {
      gsap.registerPlugin(ScrollTrigger);
      let panels = gsap.utils.toArray(".panel");
      panels.map((panel) =>
        ScrollTrigger.create({ trigger: panel, start: "top top" })
      );
      panels.forEach((panel) => {
        ScrollTrigger.create({
          trigger: panel,
          start: () =>
            panel.offsetHeight < window.innerHeight
              ? "top top"
              : "bottom bottom",
          pin: true,
          pinSpacing: false,
        });
      });
    },
    /**
     * 路由跳转方法
     * @param {*} id 文章id
     * @param {*} url 跳转路由
     */

    routerBtn(url, id) {
      if (id) {
        this.$router.push({ path: url, query: { id: id } });
        return;
      }
      this.$router.push({ path: url });
    },
  },
};
</script>

<style lang="less" scoped>
.panel {
  background: #15171d;
}
.header_video {
  height: 100vh;

  .container_max {
    width: 100vw;
    height: 100vh;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .header_title {
      position: absolute;
      top: 4.375rem;
      left: 0;
      right: 0;
      margin: 0 auto;
      text-align: center;
      z-index: 2;
    }
  }
  .container_max::after {
    content: "";
    display: inline-block;
    width: 100%;
    height: 14.1875rem;
    position: absolute;
    top: 0;
    background: rgba(22, 39, 50, 0.74);
    z-index: 1;
  }
  .container_max::before {
    content: "";
    display: inline-block;
    width: 110%;
    height: 3.6875rem;
    background: #15171d;
    filter: blur(0.3375rem);
    position: absolute;
    // padding: 0 .5313rem;
    top: 12.1875rem;
    left: -0.5313rem;
    z-index: 2;
  }

  .video_box {
    object-fit: cover;
    width: 100vw;
    height: 14.1875rem;
    background: rgba(22, 39, 50, 0.74);
  }

  .header_video_bottom {
    width: 100vw;
    height: calc(100vh - (14.1875rem));
    padding: 0 5.875rem;
    padding-top: 1.75rem;
    align-items: flex-start;
    background: #15171d;

    .jb_title {
      background-image: -webkit-linear-gradient(
        70.4152078867778deg,
        #1d73ff 50%,
        #010711 100%,
        #15171d 100%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      height: 1.4063rem;
      line-height: 1.3125rem;
    }

    .xian {
      display: inline-block;
      height: 0.7813rem;
      border: 0.0313rem solid #bdbdbd;
      margin: 0 0.8125rem 0 0.5rem;
    }

    .left_box_tips {
      margin-top: 0.9375rem;
      width: 21.375rem;
      line-height: 1.3125rem;
      padding-bottom: 3.9688rem;
      text-align: justify;
    }

    .right_box {
      .col-lg-4:last-child(1) {
        .right_box_header {
          .max_title {
            border-right: 0.0313rem solid transparent;
          }
        }
      }

      .right_box_header {
        .max_title {
          border-right: 0.0313rem solid #bdbdbd;
          height: 0.7813rem;
          padding-right: 1rem;
        }
      }

      .max_title {
        margin-left: 0.7813rem;
      }

      .chlie_box {
        margin-top: 1.25rem;

        span {
          margin-bottom: 0.625rem;
        }
      }
    }
  }
}

.project_bj_box {
  background: #15171d;
  padding-top: 1.25rem !important;
  .container {
    margin: 0 13.5rem;
    max-width: calc(100vw - 27rem);

    .proj_bj_p1 {
      margin-top: 0.9375rem;
      margin-bottom: 1.5625rem;
    }

    .proj_bj_text {
      text-align: justify;
      line-height: 1.3125rem;
      letter-spacing: 0.0313rem;
    }
  }
  .project_bj_image_g_heing {
    min-height: 15.6875rem;
  }

  .project_bj_image {
    background-image: url("https://blackwaves-1329498277.cos.ap-shanghai.myqcloud.com/images/hire/project_bj_img.png");
    background-size: contain;
    background-position: left bottom;
    background-repeat: no-repeat;
    height: 15.6875rem;
    margin-top: 4.0625rem;
    position: relative;

    .project_image_1 {
      position: absolute;
      left: 0;
      height: 15.6875rem;
    }

    .project_image_2 {
      position: absolute;
      height: 19.4063rem;
      top: -6.25rem;
      left: 9.875rem;
    }
  }
}

.project_challenge_box {
  background: #15171d;
  padding-top: 4.6875rem;
  padding-bottom: 0;

  .container {
    margin: 0 13.5rem;
    max-width: calc(100vw - 27rem);

    .proj_bj_p1 {
      margin-top: 0.9375rem;
      margin-bottom: 1.5625rem;
    }

    .proj_bj_p2 {
      margin-bottom: 2.5rem;
    }

    .proj_bj_p3 {
      margin-bottom: 3.125rem;
    }

    .proj_bj_text {
      line-height: 1.3125rem;
      letter-spacing: 0.0313rem;
      text-align: justify;
    }
  }

  .carousel_box {
    background-image: url("https://blackwaves-1329498277.cos.ap-shanghai.myqcloud.com/images/hire/project_challenge_bj.png");
    background-size: contain;
    background-position: left bottom;
    background-position-x: -0.75rem;
    background-repeat: no-repeat;
    height: 28.125rem;
    padding-top: 8.75rem;

    .carousel_item {
      // width: 100%;
      // height: 100%;
      justify-content: flex-end;
      align-content: center;

      .carousel_img {
        width: 8.4063rem;
        height: 16.4063rem;
        margin-bottom: 1.375rem;
        border-radius: 0.9375rem;
      }
    }
  }
}

.project_summarize_box {
  background: #15171d;
  padding-top: 2.75rem;

  .container {
    margin: 0 13.5rem;
    max-width: calc(100vw - 27rem);

    .proj_bj_p1 {
      margin-top: 0.9375rem;
      margin-bottom: 1.5625rem;
    }

    .proj_bj_text {
      width: 29.0938rem;
      line-height: 1.3125rem;
      letter-spacing: 0.0313rem;
      text-align: justify;
    }

    .summarize_info {
      width: 32.0625rem;
      height: 13.3125rem;
      border-radius: 0.1563rem;
      object-fit: cover;
    }
  }

  .customized_box {
    margin: 0 5.875rem;
    margin-top: 3.375rem;
    height: 7.9375rem;
    padding: 0 1.7813rem 0 1.7188rem;
    background-image: url("https://blackwaves-1329498277.cos.ap-shanghai.myqcloud.com/images/home/dingzhi.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;

    .tips_net {
      height: 0.8438rem;
    }

    .my_btn {
      width: 5.8125rem;
      height: 1.7813rem;
      border: 0.0313rem solid #ffffff;
      text-align: center;
      line-height: 1.7813rem;
    }
  }

  .case_box {
    margin: 0 5.875rem;
    margin-top: 5.3125rem;
    .case_list {
      margin-top: 0.9375rem;

      .case_item {
        width: calc(33.33% - 0.9rem);
        margin-right: 1.1875rem;

        &:nth-child(3n) {
          margin-right: 0;
        }

        .case_image_box {
          width: 100%;
          height: 14.6875rem;
          margin-bottom: 0.625rem;
          overflow: hidden;

          .case_image {
            width: 100%;
            height: 100%;
            transition: all 0.8s;
          }
        }

        &:hover {
          .case_image {
            transform: scale(1.05);
            transition: all 0.8s;
          }
        }

        .case_title {
          height: 1.25rem;
        }

        &:nth-child(4) {
          margin: 0;
        }
      }
    }
  }
}

::v-deep .el-carousel__mask {
  background: transparent;
}
</style>
